.CatchFeed {
  h2 {
    border-bottom-right-radius: 0px !important;
  }
  border: none !important;
  th {
    border: none !important;
    padding: 5px !important;
    background-color: red;
    background-color: #e6e7d5;
    color: #49614b;
    font-weight: bold !important;
    font-size: 16px;
  }
  tbody {
    background-color: #fcf9f7 !important;
    font-size: large;
  }
  tr:hover {
    background-color: #e6e7d5 !important;
  }
  tr {
    p {
      background-color: #fcf9f7 !important;
      vertical-align: auto;
      padding: 0.4rem;
      margin: 2px;
      color: #3f463c;
      border-radius: 0px;
      border-top-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    p:hover {
      color: white;
      background-color: #49614b !important;
    }

    td {
      border-bottom-style: solid !important;
      border-width: 1px !important;
      border-color: #aaaaaa !important;
      color: #49614b;
      padding: 5px;
      vertical-align: middle;
      .ImageWidth {
        display: inline-block;
        width: 20px;
        margin-left: 5px;
      }
      img {
        margin-right: 15px;
        float: right;
      }
    }
    .TableButtons {
      text-align: right;
    }
  }
}
h2 {
  margin-bottom: 0px;
}

// Mobil
.CatchFeedMobile {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  .openReport {
    text-align: right;

    img {
      margin-right: 0px;
      margin-left: 5px;
    }
  }
  .reportExpanderIcon {
    width: 13px;
    margin-left: 2px;
  }

  td {
    color: #49614b;

    img {
      margin-right: 15px;
    }
  }
  .FeedIcon {
    img {
      width: 10px;
      float: right;
      margin-top: 3px;
    }
  }
}

.ImageGallery {
  padding-bottom: 40px !important;
  width: 100%;

  .imageCollection {
    margin: 10px;
    margin-left: 30px;
  }

  .col-2 {
    margin-bottom: 10px !important;
    // width: 122%;
    // overflow: hidden;
  }
  h2 {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    width: inherit;
  }
  img {
    width: inherit;
    max-height: 200px;

    object-fit: contain;
  }
}

.stockTableAggregated {
  margin-top: 1rem;

  table {
    border-top-left-radius: 10px;
    display: table;
    margin-bottom: 20px;
    overflow: hidden;

    thead {
      background-color: #49614b;
      border: 1px solid #aaaaaa;
      tr {
        color: #e6e7d5;
        th {
          border: none;
          font-weight: normal;
        }
      }
      tr:nth-child(2) {
        text-align: center;
      }
      tr:nth-child(3) {
        background-color: #e6e7d5;
        color: #49614b;
        th {
          font-weight: bold;
        }
      }
    }
    tbody {
      background-color: #fcf9f7;
      font-size: large;
      border: 1px solid #aaaaaa;

      tr {
        td {
          border-bottom-style: solid;
          border-width: 1px;
          border-color: #aaaaaa;
          border-top: none
        }
      }

      tr:hover {
        background-color: #e6e7d5;
      }

      tr:last-child {
        background-color: #e6e7d5;
        color: #3f463c;
        font-weight: bold;
      }
    }
  }
  .speciesColumnDivider {
    border-left-style: solid;
  }
}
.GraphView {
  position: relative;
  width: 100%;
  .chart {
    position: relative;
    top: -5px;
    z-index: 0;
  }
  .chartWeb {
    height: 480px;
    background-color: #ffffff;
  }
  .GraphHeader {
    height: 40px;
    font-size: 16px;
    width: 100%;
  }

  .GraphHeading {
    position: relative;
    float: left;
    left: 5px;
    font-size: 18px;
    top: -6px;
  }
  .GraphChooser {
    float: right;
    position: relative;

    cursor: pointer;
    .graphText {
      float: right;
      position: relative;
      right: 20px;

      text-decoration: underline;
      padding-top: 5px;
      padding-bottom: 7px;
    }
  }
  .dotMenu {
    position: relative;
    float: right;
    right: -2px;
    top: 7px;

    .row {
      width: 5px;
      height: 5px;
      background-color: #e6e7d5;
      margin-bottom: 4px;
      border-radius: 50%;
    }
  }
  .GraphChooserLight {
    float: right;
    position: relative;

    text-align: center;

    .graphText {
      float: right;
      position: relative;
      right: 20px;
      padding-bottom: 7px;

      text-decoration: underline;
      padding-top: 5px;

      // background-color: #fcf9f7;
    }

    .GraphMenu {
      // height: 0px;
      float: right;
      z-index: 112;
      color: #49614b;
      position: relative;
      text-align: right;

      div:hover {
        background-color: #e6e7d5;
      }
      div {
        width: inherit !important ;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
        background-color: #fcf9f7;
      }
      div:first-child {
        border-top: solid 0.5px #49614b;
      }
    }
    cursor: pointer;
  }
  .graphChooserWrapper {
    height: auto;
    padding-bottom: 20px;

    float: right;
    position: relative;
    right: -12px;
    top: -12px;
    margin-bottom: -60px;
  }
  .graphChooserWrapperOpen {
    height: 280px;
    margin-bottom: -280px;
  }

  .GraphChooserLight {
    background-color: #fcf9f7;
    color: #49614b;
    text-align: right;
    // padding-top: 6px;
    margin-bottom: -9px;
    position: relative;
    border-bottom-right-radius: 0px !important;

    .dotMenu {
      .row {
        background-color: #49614b;
      }
    }
  }
}

//Mobile
.chartMobile {
  height: 400px;
  background-color: #ffffff;
}

.StockPageMobile {
  .GraphHeader {
    height: 40px !important;
    .GraphChooserMobile {
      .dotMenu {
        position: relative;
        top: -7px;
        right: -15px;
      }
    }
  }
  .GraphHeading {
    white-space: nowrap;
    font-size: 15px;
  }

  .GraphMenu {
    width: 100%;
    position: absolute;
    background-color: #fcf9f7;
    color: #49614b;
    margin-left: auto;
    margin-right: auto;
    right: 1px;
    margin-top: 20px;
    z-index: 1000;
    div {
      white-space: nowrap;
      margin-top: 7px;
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.loadingOverlay {
  width: 100%;
}
.StockTable {
  border-top-left-radius: 10px !important;
  width: 100%;
  margin-top: 2vh;

  .NameSearch {
    position: relative;
    top: 7px;
    input {
      background-color: #49614b;
      border-style: none;
      color: #e6e7d5 !important;
      border-bottom: solid 0.5px #e6e7d5 !important;
      padding: 0px;
      border-radius: 0px !important;
      svg {
        visibility: hidden;
      }
    }

    input::placeholder {
      color: #e6e7d5 !important;
    }
  }

  .StockTableHeaderWrapper {
    border-top-left-radius: 10px !important;
    background-color: #49614b;

    border-right: solid 0.5px #49614b;
    th {
      color: #e6e7d5 !important;
      font-weight: normal;
      border: none !important;
      white-space: nowrap;
    }
    .firstTH {
      border-top-left-radius: 10px !important;
    }
  }
  .StockTableHeader {
    background-color: #e6e7d5;
    border-left: solid 0.5px #aaaaaa;
    border-right: solid 0.5px #aaaaaa;
    th {
      color: #49614b !important;
      font-weight: 900;
      border-bottom: solid 0.5px #aaaaaa;
      white-space: nowrap;
      span {
        cursor: pointer;
        img {
          width: 13px;
        }
      }
    }
  }
  tbody {
    background-color: #fcf9f7 !important;
    font-size: large;
    tr:hover {
      background-color: #e6e7d5 !important;
    }
    tr {
      border-style: solid !important;
      border-width: 1px;
      border-color: #aaaaaa !important;
      td {
        border-bottom-style: solid !important;
        border-width: 1px !important;
        border-color: #aaaaaa !important;
      }
    }
  }
}

// Mobile
.StockTableMobile {
  table {
    width: calc(100%+40px);
    border-top-left-radius: 5px !important;
    overflow-x: scroll;
    display: block;
    margin-bottom: 20px;
    td {
      white-space: nowrap;
    }

    .mobileTableHeader {
      color: #e6e7d5 !important;
      font-weight: normal !important;
    }
  }

  .StockTableHeaderWrapperMobile {
    background-color: #49614b;
    width: 200px;
    border-top-left-radius: 5px !important;

    .NameSearch {
      width: 280px;
    }

    .StockTableMobileNameSearch {
      padding: 10px !important;
    }
  }
}

.riverTable {
  .loadingTable {
    width: 500px;
    height: 1000px;
    background-color: #49614b;
  }
  th {
    padding: 0.75rem;
    vertical-align: top;
    border-bottom: 1px solid #aaaaaa !important;
    border-top: none !important;
  }
  td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #aaaaaa !important;
  }
  thead {
    tr {
      border-style: none !important;
    }
  }

  tbody {
    background-color: #fcf9f7 !important;
    font-size: large;
    border-bottom: solid 0.5px #aaaaaa;
    tr {
      border-left: 1px solid #aaaaaa !important;
      border-right: 1px solid #aaaaaa !important;
    }
    tr:hover {
      background-color: #e6e7d5 !important;
    }
    tr {
      a {
        background-color: #fcf9f7 !important;
        vertical-align: auto;
        padding: 0.4rem;
        margin: 2px;
        color: #3f463c;
        border-radius: 0px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-color: #49614b !important;
      }
      a:hover {
        color: white;
        background-color: #49614b !important;
      }

      td {
        padding: 0.5rem;
        vertical-align: middle;
        font-weight: bold;
      }
      .TableButtons {
        text-align: right;
      }
    }
  }
}

//Mobile
.riverTableMobile {
  width: 100% !important;
  background-color: #e6e7d5;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
  table {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    tbody {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      display: inline-table;
      position: relative;
      padding-bottom: 20px;
    }
    tr {
      background-color: #fcf9f7 !important;
      margin-left: auto;
      margin-right: auto;
      padding: 0px !important;
      display: inline-block;
      margin-top: 10px !important;
      width: 100%;
      border-style: solid !important;
      border: #49614b;
      border-width: 0.5px !important;
      border-top-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      td {
        padding: 0px !important;
        border-style: none !important;
        width: 100%;
        display: inline-block;
      }

      a {
        vertical-align: auto;
        padding: 0.4rem;
        margin: 2px;
        color: #3f463c;
        border-radius: 0px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-color: #49614b !important;
      }
      a:hover {
        color: white;
        background-color: #49614b !important;
      }
      .leftButton {
        position: relative;
        margin-left: 1%;
        float: left;
        a {
          background-color: #fcf9f7 !important;
        }
      }
      .rightButton {
        position: relative;
        float: right;
        margin-right: 1%;
        a {
          background-color: #49614b !important;
          color: #fcf9f7;
        }
      }
      .row {
        display: inline-block !important;
        width: 100%;
        margin: 0px !important;
      }
      .riverTableHeading {
        font-weight: bold;
        padding: 10px;

        hr {
          border-top: 2px solid #49614b;
          width: inherit;
          text-align: left;
          margin-left: 0px;
          margin-top: 5px;
        }
      }
    }
  }
}

:root {
  --font-body: 'Inter', sans-serif;
  --font-heading: 'Alegreya', serif;
}

body {
  background-color: #fcf9f7 !important;
  font-family: var(--font-body) !important;
  font-size: 16px;
}
main {
  background-color: #fcf9f7;
}

.CountySearch {
  list-style: none;

  li {
    background-color: transparent;
    border: none;
    input {
      width: 100% !important;
      border: none;
      background-color: #fcf9f7 !important;
      padding: 5px !important;
      padding-left: 5px !important;

      box-sizing: border-box;
      border-bottom: solid;
      border-radius: 0px;
      border-width: 1px;
      color: black !important;

      margin-bottom: 5px !important;
    }

    button {
      width: 100%;
      background-color: #e6e7d5;
      color: #3f463c;
      margin-top: 5px;
      padding: 10px !important;
      border-top-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.ButtonGreen {
  text-align: center;
  color: white;
  background-color: #49614b;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.ButtonGreen:hover {
  background-color: #e6e7d5;
  color: black;
}

.container {
  padding: 0px !important;
}
.LandingPageMain {
  margin: auto;
  width: 160vh;
  position: relative;
  top: -29vh;
  margin-bottom: -29vh;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;

  .searchContainer {
    padding: 15px !important;
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .TextBox {
    h4 {
      color: #49614b !important;
      font-size: 4vh;
      span {
        font-weight: bold;
      }
    }
    hr {
      border-top: 2px solid #e6e7d5;
      width: 135px;
      text-align: left;
      margin-left: 0px;
    }
    p {
      color: #49614b !important;
    }
  }
}
.content {
  min-height: calc(80vh - 270px);
}
.Footer {
  color: #e6e7d5;
  font-size: 14px;
  background-color: #49614b;
  hr {
    border-top: 3px solid #e6e7d5;
    color: #e6e7d5;
    width: 200px;
    text-align: left;
    margin-left: 0px;
  }
  a {
    color: #e6e7d5;
  }
  a:visited {
    color: #e6e7d5 !important;
  }
  h4 {
    font-size: 18px;
    font-weight: bold;
    color: #e6e7d5;
    margin-bottom: 2px;
  }
  p {
    margin: 0px;
  }
  .FooterDecor {
    background-color: #fcf9f7;
    img {
      width: 100%;
    }
  }

  .FooterContent {
    margin: 36px;
    height: 140px;

    ul {
      list-style: none;
    }
    .FooterLeftside {
      float: left;
    }
    .FooterRightside {
      float: right;
      text-align: right;
      text-decoration: underline;
      font-weight: bolder;

      li {
        margin-top: 15px;
        a {
          text-decoration: underline;
        }
      }
    }
  }
  a:visited {
    color: gray;
  }
  a:hover {
    color: gray;
  }
}
header {
  .logo {
    padding-left: 9px;
    a {
      color: #7e8082;
    }
    img {
      width: 120px;
    }

    .logotext {
      position: relative;
      top: 17px;
      left: -75px;
      font-size: 12px;
    }
  }
  height: 85vh;
  .top {
    min-height: 7vh;
    background-color: #fffdfa;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > * {
      align-self: center;
    }

    .top-bar__register-catch-button {
      margin-right: 9px;

      & button {
        padding: 8px;
        padding-bottom: 6px;
        background-color: #fcf9f7;
        font-weight: normal;
        font-size: 0.8em;
        color: #3f463c;
        width: 100%;
        min-height: 3vh;
        a {
          color: #3f463c;
        }
      }
    }
  }
  .bottom {
    img {
      width: 100%;
    }
  }
}
header.SubPage {
  height: 10vh;
}

h2,
.heading {
  background-color: #49614b;
  color: #e6e7d5;
  text-align: center;
  padding: 15px;
  height: 5vh !important;
  font-size: 16px !important;
}

.buttonSetting {
  a {
    text-align: center;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    color: #3f463c;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  a:visited {
    color: #3f463c;
  }
}

.borderSetting,
.noBorderSetting {
  border-width: 1px;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-color: #49614b !important;
}
.borderSetting {
  border-style: solid;
}

.borderSettingBig {
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.shadowSetting {
  box-shadow: 0 10px 12px 0 rgba(122, 121, 79, 0.29);
  margin-bottom: 20px;
}
.smallBoxSetting {
  // box-shadow: 5px 5px 10px 12px 0 rgba(122, 121, 79, 0.29) !important;

  box-shadow: 0 10px 12px 0 rgba(122, 121, 79, 0.29);
  margin-bottom: 20px;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: 15px !important;
  margin-left: 15px !important;
}
.StockPage {
  // .StockPageTop {
  //   margin-left: 0px !important;
  //   margin-right: 0px !important;
  // }
  .StockPageBottom {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .stockPageButtons {
    font-size: 14px;
    white-space: nowrap;
    .row {
      margin-bottom: 0px;
    }

    a:visited {
      color: #3f463c;
    }

    button {
      padding: 10px !important;
      background-color: #fcf9f7;
      font-weight: bold;
      color: #3f463c !important;
      width: 100%;
      margin-bottom: 13px;
      height: 5vh !important;
      a {
        color: #3f463c !important;
      }
    }
    button:hover,
    .ButtonSelected {
      color: white !important;
      background-color: #49614b !important;
      a {
        color: white !important;
        background-color: #49614b !important;
      }
    }
  }

  .stockPageOptions {
    .col {
      width: 100% !important;
    }
    .input-group {
      select {
        color: #444444;
        height: 5vh !important;
        background-color: #fcf9f7 !important;

        option {
          width: 100px !important;
          padding: 10px !important;
          padding-top: 20px !important;
          padding-bottom: 40px !important;
        }
      }
    }
  }
}
.clearBoth {
  clear: both;
  height: 0px;
  padding: 0px !important;
}

.popup-content {
  background: #49614b;
  border-style: none;

  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;

  img {
    position: relative;
    width: 100% !important;
    object-fit: contain;
    max-height: 400px;
  }
}

.paginateButtons {
  width: 100%;

  button {
    background-color: #fcf9f7 !important;
    vertical-align: auto;
    padding: 0.4rem;
    margin: 2px;
    color: #3f463c;
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: #49614b !important;
    border-width: 0.5px;
  }

  button:hover {
    background-color: #49614b !important;
    color: white;
  }
  .leftPageButton {
    float: left;
    width: 200px;
    margin: 0px !important;
    padding: 0px !important;

    button {
      float: left;
    }
  }
  .rightPageButton {
    float: right;
    width: 200px;
    margin: 0px !important;
    padding: 0px !important;
    button {
      float: right;
    }
  }
}
// Mobile:

.landingPageMobile {
  .imageSlider {
    margin-top: 20px;
    h2 {
      margin-bottom: 10px;
    }
  }
  h2 {
    width: 100% !important;
    height: 45px !important;
    display: inline-block;
    font-size: 14px !important;
    text-align: center;
    white-space: nowrap;
  }
  position: relative;
  top: -50vh;
  margin-bottom: -50vh;
  background-color: #fcf9f7;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  .countySearchMobile {
    width: 100%;
    .list-group-item {
      width: 100% !important;
      margin: 0px !important;
      padding: 0px !important;
      margin-bottom: 10px !important;
    }
  }

  .mobileBoxSetting {
    box-shadow: 0 10px 12px 0 rgba(122, 121, 79, 0.29);
    margin-bottom: 20px;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .TextBox {
    color: #49614b;
    text-align: center;

    width: inherit;
    h4 {
      color: #49614b !important;
      font-size: 24px;
      text-align: center;
      width: 100%;
      span {
        font-weight: bold;
        font-size: 30px !important;
      }
    }
    hr {
      border-top: 2px solid #e6e7d5;
      width: 80%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      color: #49614b !important;
    }
  }
  .ScrollSymbol {
    width: 60px;
    height: 60px;
    margin-bottom: -30px;

    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
}

//Stock page mobile
.StockPageMobile {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  .paginateButtons {
    .leftPageButton {
      width: 100px;
      white-space: nowrap;
    }
    .rightPageButton {
      width: 100px;
      white-space: nowrap;
    }
  }

  .TableOptionsMobile {
    background-color: #3f463c;
    color: white !important;
    margin-top: 10px;
    margin-bottom: -2px !important;

    select {
      padding-top: 0px !important;
      padding-bottom: 0px !important;

      background-color: #3f463c;
      color: #ffffff;
      border-style: none;
      text-align: center !important;
    }
    select:focus {
      outline: none;
    }
  }

  .StockPageBottom {
    margin: 0px !important;
  }
  .stockPageOptionsMobile {
    .input-group {
      .custom-select {
        background-color: #fcf9f7 !important;
        color: #444444 !important;
      }
    }
  }
  .fiskeKort {
    margin-bottom: 10px;
  }
  .input-group {
    margin-bottom: 10px !important;
  }
  .ChartData {
    div {
      padding-bottom: 30px;
      div {
        padding-bottom: 0px;
      }
    }
  }
}
.input-group {
  margin-bottom: 15px !important;
}

.cursorPointer {
  cursor: pointer;
}
.underline {
  text-decoration: underline;
}
.odd {
  background-color: #e6e7d5;
}
.boldFont {
  font-weight: bold;
}
.alignRight {
  text-align: right;
}
.FooterMobile {
  ul {
    padding: 0px;
    margin: 0px;
  }
  .FooterContent {
    margin: 0px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    padding-bottom: 50px;
    p {
      margin: 10px !important;
      text-align: center;
      a {
        margin-left: 3px;
        margin-right: 3px;
      }
      a:visited {
        color: #e6e7d5;
      }
    }
  }

  .footerExpander,
  .footerLink {
    width: 100%;

    h4 {
      text-align: center;
      margin-top: 20px;
    }

    img {
      width: 10px;
      margin-right: 50px;
      margin-left: -50px;
      float: right;
      margin-top: 7px;
    }
  }
  .footerLink {
    h4 {
      a:visited {
        color: #e6e7d5;
      }
      a {
        text-decoration: underline;
      }
    }
  }
}

// App advertisment
.appAdvertisement {
  padding-top: 25px;
  margin-top: 25px;
  width: 100%;
  background-color: #fcf9f7;

  .appAdvertisement__content {
    max-width: 800px;
    margin: 0 auto;
  }

  text-align: center;

  p {
    margin-top: 20px;
    margin-bottom: 50px;
  }
}

.ChartData {
  // margin-bottom: 20px;
  padding: 0px;
  color: #3f463c;
  font-weight: bold;
  margin-top: 0px !important;
  border-style: none !important;
  border-top-left-radius: 10px !important;

  border-right: solid 0.5px #49614b;
  th {
    color: #e6e7d5 !important;
    font-weight: normal;
    border: none !important;
    white-space: nowrap;
  }
  .firstTH {
    border-top-left-radius: 5px !important;
  }
  .lastTD,
  tbody,
  table,
  tr {
    border-bottom-right-radius: 5px !important;
  }

  table {
    border: none !important;
    thead {
      border-radius: 10px !important;
      border: none !important;
      background-color: #49614b;
    }
    tr {
      border: none !important;
    }
    th {
      text-align: center;
      font-weight: bold !important;
      border: none !important;
    }
    td {
      color: #3f463c !important;
      text-align: center;
      background-color: #e6e7d5;
      border: none !important;
    }
  }
  .row {
    margin: 0px;
    background-color: #e6e7d5;
    margin-bottom: 10px;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    height: 5vh !important;

    .alignLeft {
      text-align: left;
    }
    .alignRight {
      text-align: right;
    }
  }

  .col {
    padding: 0px;
  }
}

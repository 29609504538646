.popup-content {
  /* margin: auto !important; */
  // display: none;
  /* position: relative; */
  width: 70vh !important;

  div {
    .popup-arrow {
      display: none !important;
    }
  }
}

.MapSelectorWrapper {
  height: auto;
  padding: 5vh;
  .CountyInfo {
    border-style: solid;
    border-width: 1px;
    position: relative;
    top: -300px;
    height: 40px;
    left: 300px;
    width: 220px;
    text-align: center;
    padding: 10px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #e6e7d5;
  }
  .CountyLift {
    margin-top: -40px;
  }
  .Visible {
    visibility: visible;
  }
  .Hidden {
    visibility: collapse;
  }
  .BorderWrapper {
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    h4 {
      background-color: #e6e7d5;
      text-align: center;
      padding: 1vh;
      border-bottom: solid;
      border-width: 1px;
      border-top-left-radius: 15px;
    }
    .map {
      margin: 20px;
    }
    svg {
      a:hover {
        fill: #3f463c;
      }
      a {
        fill: #e6e7d5;
      }
      a:focus {
        fill: pink;
        stroke: red;
        stroke-width: 1;
      }
      .clicked {
        fill: #3f463c;
      }
    }
  }

  // margin: auto;
  // position: fixed;
  // top: 50%;
  // left: 50%;

  background-color: #fcf9f7;
  // padding: 5vh;
}

//Mobile
// County map
.mobileCountyMap-content {
  @media only screen and (min-width: 700px) {
    body {
      width: 90% !important;
    }
  }
  background-color: #e6e7d5;
  .close {
    display: block;
  }
  .xClose,
  .textClose {
    font-weight: bold;
  }
  .MapSelectorWrapper {
    padding: 5px;
    width: 100%;
    margin-top: 34px;
    background-color: #e6e7d5;
  }
  .CountyInfo {
    left: 0px !important;
    width: 100% !important;
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    top: -10px !important;
    background-color: white !important;
    white-space: nowrap;
    border-style: none !important;
    text-align: center !important;
    span {
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      font-weight: bold;
    }
  }
}

.CatchReport {
  background-color: #fcf9f7;
  width: 100%;
  clear: both;

  .popup-content {
    background-color: black !important;
  }

  .CatchReportDataWrapper {
    padding: 5px;
    // margin: 20px;
    margin-top: 20px;
    width: 100%;
    font-weight: bold;
    border: solid 0.5px #e6e7d5 !important;
  }
  .CatchCol {
    position: relative;
    left: 0px;
  }
  .CatchData {
    float: right;
    position: relative;
    right: 0px;
  }
  .popup-content {
    background: #fcf9f7;
    border-style: none;

    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    img {
      position: relative;
      width: 80% !important;
    }
  }
}

.feedWeb-content {
  border-color: #fcf9f7;
  background-color: #fcf9f7;
  .imageFrame {
    max-height: 400px;
    overflow: hidden;
    width: 100%;
  }
  img {
    width: 100% !important;
  }
}
.xClose {
  text-align: center;
  color: #5b5a59;
  cursor: pointer;
}
.textClose {
  color: #5b5a59;
  font-size: 12px;
  cursor: pointer;
}

// Mobile
.feedMobile-content {
  border-color: #fcf9f7;
  background-color: #fcf9f7;
  .imageFrame {
    max-height: 250px;
    overflow: hidden;
    width: 100%;
  }

  .CatchReport {
    width: 100% !important;
  }
  img {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

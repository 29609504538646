:root {
    --main-green: #49614b;
    --select-color: #444;
}


/*
    Filter button component
*/
.filter-button {
    float: right;
}

.filter-button__button {
    margin-left: 10px;
    padding: 5px 10px;
    position: relative;
    top: 6px;
}

.filter-button__icon {
    color: red;
}

.filter-button.mobile {
    float: none !important;
    width: 100px;
}

.filter-button.mobile button {
    margin-left: 0;
}


/*
Filter page component
*/
.filter-page {
    padding: 10px;
}

.filter-page h2 {
    margin-bottom: 10px;
}

.filter-page select,
.filter-page select option {
    color: var(--select-color);
}

.filter-page-section {
    margin-bottom: 20px;
}

.filter-page__buttons {
    float: right;
}

.filter-page__buttons * {
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
}

.filter-page__buttons .button-primary {
    background-color: var(--main-green);
    color: white;
}
